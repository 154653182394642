<template>
  <div class="">
    <am-list-page
      title="Numberings"
      :columns="columns"
      :rows="numberings"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
    >
      <template slot="right-extra-header-actions">
        <div
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <am-button
            icon="SettingsIcon"
            label="Numberings"
            tool-tip-text="Setup & Manage Numberings"
            class=""
            @click="showNumberingSetupModal()"
          />
        </div>
      </template>
      <template #type="{row}">
        <b-badge variant="light-primary">
          {{ row.item.type.toString().replaceAll("Organization","Farmer Group").toUpperCase() }}
        </b-badge>
      </template>
      <template #action="{row}">
        <am-button
          icon="Edit2Icon"
          icon-only
          tool-tip-text="Click to edit"
          variant="flat-primary"
          @click="showNumberingModalForEdit(row.item)"
        />
      </template>
    </am-list-page>
    <numbering-setup
      :id="selectedNumberingId"
      :modal-opened="numberingsModalOpened"
      @modalClosed="closeModal('numberings')"
    />
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmListPage from '@/farmbusiness/components/AmListPage.vue'
import NumberingSetup from '@/views/administration/numberings/NumberingSetup.vue'

export default {
  name: 'Index',
  components: {
    NumberingSetup,
    AmListPage,
    AmButton,
    BBadge,
  },
  data() {
    return {
      tableLoading: false,
      numberingsModalOpened: false,
      selectedNumberingId: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          label: 'Type',
          key: 'type',
        },
        {
          label: 'Prefix',
          key: 'prefix',
        },
        {
          label: 'Length',
          key: 'length',
        },
        {
          label: 'Date Created',
          key: 'created_at',
        },
        {
          label: 'Actions',
          key: 'action',
        },
      ],
    }
  },
  computed: {
    numberings() {
      return this.$store.getters['numbering/numberings']
    },
  },
  mounted() {
    this.$store.dispatch('numbering/fetchNumberings')
  },
  methods: {
    showNumberingModalForEdit(item) {
      this.selectedNumberingId = item.id
      this.numberingsModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'numberings':
          this.numberingsModalOpened = false
          this.selectedNumberingId = null
          break
      }
    },
    showNumberingSetupModal() {
      this.numberingsModalOpened = true
    },
  },
}
</script>

<style scoped>

</style>
