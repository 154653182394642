<template>
  <!--    Numbering Form-->
  <validation-observer
    ref="numberingForm"
    v-slot="{invalid}"
  >
    <am-modal
      :title="`${updateNumberingMode?'Update':'Create'} Numbering`"
      :show="numberingModalOpened"
      :show-overlay="deletingNumbering"
      size="md"
      @onClose="closeNumberingModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <am-select
            v-model="selectedNumberingId"
            placeholder="All Numberings"
            :options="numberings"
            label-name="prefix"
            value-name="id"
            name="numberings"
            @input="populateNumberingForm"
          />
        </div>
        <div class="col-2">
          <am-dropdown
            icon="TrashIcon"
            name="delete_numbering"
          >
            <b-dropdown-item
              id="cd_numbering"
              @click="deleteNumbering()"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </am-dropdown>
        </div>
        <div class="col-2">
          <am-button
            name="numbering_action"
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @click="resetNumberingForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <am-select
        v-model="numbering.type"
        name="type"
        label="Numbering Type"
        rules="required"
        :options="numberingTypes"
      />
      <am-input
        v-model="numbering.prefix"
        name="prefix"
        label="Prefix"
        rules="required|max_length:3"
      />
      <am-input
        v-model="numbering.length"
        name="length"
        label="Length"
        rules="required|min_value:10"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <am-button
            name="cancel"
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @click="closeNumberingModal()"
          />
          <am-button
            name="save_numbering"
            :label="updateNumberingMode?'Update':'Create'"
            :disabled="invalid"
            :loading="numberingLoading"
            loading-text="Loading..."
            @click="updateNumberingMode?updateNumbering():createNumbering()"
          />
        </b-row>
      </template>
    </am-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { serialize } from 'object-to-formdata'
import { ValidationObserver } from 'vee-validate'
import logData from '@/libs/log'
import AmInput from '@/farmbusiness/components/AmInput.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmDropdown from '@/farmbusiness/components/AmDropdown.vue'
import AmModal from '@/farmbusiness/components/AmModal.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import ErrorDisplay from '@/farmbusiness/components/AmErrorDisplay.vue'

const numb = {
  id: null,
  type: null,
  prefix: null,
  length: null,
}
export default {
  name: 'NumberingSetup',
  components: {
    ErrorDisplay,
    AmModal,
    AmDropdown,
    AmButton,
    AmSelect,
    AmInput,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      numberingModalOpened: false,
      updateNumberingMode: false,
      numberingLoading: false,
      deletingNumbering: false,
      selectedNumberingId: null,
      numbering: { ...numb },
      numberingTypes: [
        { label:'Farmer Group',value:'Organization' },
        { label:'Member',value:'Member' },
        { label:'Contribution',value:'Contribution' },
      ],
    }
  },
  computed: {
    selectedNumbering() {
      const numbering = this.numberings.find(o => o.id === this.selectedNumberingId)
      if (!numbering) {
        return null
      }
      return {
        ...numbering,
      }
    },
    numberings() {
      return this.$store.getters['numbering/numberings']
    },
  },
  watch: {
    modalOpened(opened) {
      this.numberingModalOpened = opened
    },
    id(id) {
      this.selectedNumberingId = id
    },
  },
  methods: {
    resetNumberingForm() {
      this.numbering = { ...numb }
      this.selectedNumberingId = null
      this.updateNumberingMode = false
      this.$refs.numberingForm.reset()
    },
    populateNumberingForm(numbering) {
      if (this.selectedNumbering) {
        this.updateNumberingMode = true
        this.numbering = { ...this.selectedNumbering }
      } else {
        this.resetNumberingForm()
      }
    },
    closeNumberingModal() {
      this.numberingModalOpened = false
      this.$emit('modalClosed')
    },
    createNumbering() {
      this.error = {}
      this.numberingLoading = true
      const numbering = serialize(this.numbering)
      this.$store.dispatch('numbering/createNumbering', numbering)
        .then(res => {
          this.showSuccess('Created numbering successfully')
          this.resetNumberingForm()
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to create numbering')
        }).finally(() => {
          this.numberingLoading = false
        })
    },
    updateNumbering() {
      this.error = {}
      if (!this.numbering.id) {
        return
      }
      this.numberingLoading = true
      if (!this.numbering.logo) {
        delete this.numbering.logo
      }
      const numbering = serialize(this.numbering)
      this.$store.dispatch('numbering/updateNumbering', { numbering, id: this.numbering.id })
        .then(res => {
          this.showSuccess('Updated numbering successfully')
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update numbering')
        }).finally(() => {
          this.numberingLoading = false
        })
    },
    deleteNumbering() {
      if (!this.selectedNumberingId) {
        this.showInfo('Please select a numbering')
        return
      }
      this.deletingNumbering = true
      this.$store.dispatch('numbering/deleteNumbering', this.selectedNumberingId)
        .then(() => {
          this.showSuccess('Deleted Successfully')
          this.resetNumberingForm()
        })
        .catch(() => {
          this.showError('Failed to delete numbering')
        }).finally(() => {
          this.deletingNumbering = false
        })
    },
  },
}
</script>
